<template>
  <main>
    <trac-loading v-if="isLoading" />
    <div class="flex w-full justify-center mt-16 items-center">
      <div
        ref="plac"
        class="
          max-w-4xl
          auth-shadow
          p-5
          flex-col
          pb-16
          rounded-md
          w-full
          flex
          items-center
          justify-center
        "
      >
        <div class="flex items-center justify-center flex-col mt-12 mb-6">
          <img src="@/assets/images/traction-logo.png" class="w-48" alt="" />
          <!-- <h2 class="text-sm pt-2 font-medium text-primaryBlue tracking-wide">
            traction apps
          </h2> -->
        </div>
        <trac-stepper
          :steps="3"
          :current="current"
          class="mt-6 w-10/12 md:w-1/3"
        />
        <div class="max-w-xl ma w-full mt-10">
          <h1 class="text-lg font-medium text-accentDark">Create Account</h1>
          <trac-validation-observer slim v-slot="{ handleSubmit }">
            <form
              v-show="current == 1"
              @submit.prevent="handleSubmit(addPersonalInfo)"
              class="max-w-xl ma w-full mt-10 relative"
            >
              <trac-validation-provider
                tag="div"
                name="first name"
                rules="required|alpha"
                v-slot="{ errors }"
                class="relative"
              >
                <trac-input
                  v-model.trim="authUser.firstName"
                  placeholder="First Name"
                  class="mt-5"
                />
                <trac-input-error v-if="errors[0]">{{
                  errors[0]
                }}</trac-input-error>
              </trac-validation-provider>

              <trac-validation-provider
                name="last name"
                rules="required|alpha"
                v-slot="{ errors }"
                tag="div"
              >
                <trac-input
                  v-model.trim="authUser.lastName"
                  type="text"
                  placeholder="Last Name"
                  class="mt-6"
                />
                <trac-input-error v-if="errors[0]">{{
                  errors[0]
                }}</trac-input-error>
              </trac-validation-provider>

              <div class="mt-8 flex items-center justify-center flex-col">
                <div class="flex">
                  <trac-button
                    :disabled="isLoading"
                    v-if="current !== 1"
                    @button-clicked="current -= 1"
                    variant="outline"
                    class="w-48 mr-5"
                    ><div class="leading-7">PREVIOUS</div></trac-button
                  >
                  <trac-button buttonType="submit" class="w-48"
                    ><div class="leading-7">
                      {{ currentFormState ? "NEXT" : "SIGN UP" }}
                    </div></trac-button
                  >
                </div>
                <p class="text-xs text-primaryGray text-center mt-6">
                  By clicking “Sign Up” I agree to Traction Terms of Service and
                  Privacy. <br />
                  Already have an account?
                  <router-link to="/login" class="text-primaryBlue font-medium"
                    >Login</router-link
                  >
                </p>
              </div>
            </form>
          </trac-validation-observer>

          <trac-validation-observer slim v-slot="{ handleSubmit }">
            <form
              v-show="current == 2"
              @submit.prevent="handleSubmit(addContactInfo)"
            >
              <trac-validation-provider
                name="phone number"
                rules="required|integer|max:11"
                v-slot="{ errors }"
                tag="div"
              >
                <trac-input
                  v-model.trim="authUser.phoneNumber"
                  type="tel"
                  placeholder="Phone Number"
                  class="mt-5"
                />
                <trac-input-error v-if="errors[0]">{{
                  errors[0]
                }}</trac-input-error>
              </trac-validation-provider>

              <trac-validation-provider
                rules="required|email"
                v-slot="{ errors }"
                tag="div"
                name="email"
              >
                <trac-input
                  v-model.trim="authUser.email"
                  type="email"
                  placeholder="Email"
                  class="mt-6"
                />
                <trac-input-error v-if="errors[0]">{{
                  errors[0]
                }}</trac-input-error>
              </trac-validation-provider>

              <trac-validation-provider
                rules="required|min:8|atleastonenumber|atleastoneuppercase|atleastonelowercase|atleastonespecialcharacter"
                tag="div"
                name="password"
              v-slot="{ errors }"
              >
                <trac-input
                  v-model.trim="authUser.password"
                  type="password"
                  specified="password"
                  placeholder="Password"
                  class="mt-6"
                />
                <trac-input-error v-if="errors[0]">{{
                  errors[0]
                }}</trac-input-error>
              </trac-validation-provider>

              <div class="shadow-md rounded-md p-4 mt-2">
                <p class="text-xs my-1 font-semibold">
                  Password {{ ratingPassword.title }}
                </p>
                <div class="my-2 bg-transparentBackground rounded-sm h-1">
                  <div
                    class="rounded-sm h-1"
                    v-bind:style="{
                      'background-color': ratingPassword.color,
                      width: completedPasswordMeter + '%',
                    }"
                  ></div>
                  <!-- v-bind:style="{ color: activeColor, fontSize: fontSize + 'px' }" -->
                </div>
                <div class="mt-4">
                  <ul class="grid grid-cols-2 gap-2">
                    <li
                      :class="
                        hasUpperCase ? 'font-semibold text-secondaryGreen' : ''
                      "
                      class="font-light"
                      style="font-size: 0.6rem"
                    >
                      One uppercase letter E.g “A”
                    </li>
                    <li
                      :class="
                        hasMinChar ? 'font-semibold text-secondaryGreen' : ''
                      "
                      class="font-light"
                      style="font-size: 0.6rem"
                    >
                      Minium of 8 characters Eg “Abc...”
                    </li>
                    <li
                      :class="
                        hasNumber ? 'font-semibold text-secondaryGreen' : ''
                      "
                      class="font-light"
                      style="font-size: 0.6rem"
                    >
                      One number E.g “1”
                    </li>
                    <li
                      :class="
                        hasLowerCase ? 'font-semibold text-secondaryGreen' : ''
                      "
                      class="font-light"
                      style="font-size: 0.6rem"
                    >
                      One lowercase letter E.g “a”
                    </li>
                    <li
                      :class="
                        hasSymbol ? 'font-semibold text-secondaryGreen' : ''
                      "
                      class="font-light"
                      style="font-size: 0.6rem"
                    >
                      One symbol or special character E.g “.,*!”
                    </li>
                  </ul>
                </div>
              </div>

              <!-- <ul class="p-2 mt-2 bg-accentBlue">
                <p class="text-xs text-primaryBlue font-medium">
                  Password must:
                </p>
                <li>
                  <p class="text-xs text-primaryBlue font-medium">
                    be at least 8 characters long
                  </p>
                </li>
                <li>
                  <p class="text-xs text-primaryBlue font-medium">
                    contain at least one uppercase letter
                  </p>
                </li>
                <li>
                  <p class="text-xs text-primaryBlue font-medium">
                    contain at least one lowercase letter
                  </p>
                </li>
                <li>
                  <p class="text-xs text-primaryBlue font-medium">
                    contain at least one number. E.g “1”
                  </p>
                </li>
                <li>
                  <p class="text-xs text-primaryBlue font-medium">
                    contain at least one symbol or special character except the
                    asterisk “*” symbol.
                  </p>
                </li>
              </ul> -->

              <div class="mt-8 flex items-center justify-center flex-col">
                <div class="flex">
                  <trac-button
                    v-if="current !== 1"
                    @button-clicked="current -= 1"
                    variant="outline"
                    class="w-48 mr-5"
                    ><div class="leading-7">PREVIOUS</div></trac-button
                  >
                  <trac-button buttonType="submit" class="w-48"
                    ><div class="leading-7">
                      {{ currentFormState ? "NEXT" : "SIGN UP" }}
                    </div></trac-button
                  >
                </div>
                <p class="text-xs text-primaryGray text-center mt-6">
                  By clicking “Sign Up” I agree to Traction Terms of Service and
                  Privacy. <br />
                  Already have an account?
                  <router-link to="/login" class="text-primaryBlue font-medium"
                    >Login</router-link
                  >
                </p>
              </div>
            </form>
          </trac-validation-observer>

          <trac-validation-observer slim v-slot="{ handleSubmit }">
            <form
              @submit.prevent="handleSubmit(signUserUpAction)"
              class="max-w-xl ma w-full mt-10 relative"
            >
              <div v-show="current == 3">
                <trac-validation-provider
                  rules="required"
                  v-slot="{ errors }"
                  name="business name"
                  tag="div"
                >
                  <trac-input
                    placeholder="Business Name"
                    v-model.trim="authUser.businessName"
                    type="text"
                    class="mt-5"
                    tag="div"
                    name="business name"
                  />
                  <trac-input-error v-if="errors[0]">{{
                    errors[0]
                  }}</trac-input-error>
                </trac-validation-provider>

                <trac-validation-provider
                  rules="required"
                  v-slot="{ errors }"
                  tag="div"
                  name="business category"
                >
                  <!-- <select
                    name="business category"
                    v-model="authUser.businessCategory"
                    class="
                      mt-6
                      w-full
                      text-gray-500
                      border
                      p-3
                      text-xs
                      rounded-md
                    "
                  >
                    <option disabled value="">Business Category</option>
                    <option
                      v-for="(category, index) in categories"
                      :key="index"
                    >
                      {{ category }}
                    </option>
                  </select> -->
                  <div class="relative w-full">
                    <label class="relative w-full cursor-pointer">
                      <input
                        v-click-outside="close"
                        @click="toggleActive"
                        readonly
                        class="
                          border
                          cursor-pointer
                          py-3
                          text-accentDark
                          blacj
                          mt-6
                          leading-6
                          mt-x
                          pl-5
                          w-full
                          text-xs
                          rounded
                          input-area
                        "
                        placeholder=" "
                        v-model="authUser.businessCategory"
                      />
                      <span class="text-xs leading-5 text-primaryBlue">{{
                        placeholder
                      }}</span>
                      <svg
                        class="fill-current h-4 w-4 absolute right-0 -mt-8 mr-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path
                          d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                        />
                      </svg>
                    </label>
                    <ul
                      v-show="active"
                      class="
                        w-64
                        absolute
                        capitalize
                        z-10
                        top-0
                        mt-20
                        mb-5
                        h-64
                        overflow-y-auto
                        rounded
                        border
                        white-gradient
                        cursor-pointer
                        text-primaryBlue
                        pt-1
                      "
                    >
                      <li
                        :class="
                          authUser.businessCategory == category
                            ? ' bg-primaryBlue text-white rounded-none hover:bg-primaryBlue px-4 py-2 whitespace-no-wrap text-xs'
                            : 'hover:bg-accentLight bg-white text-xs py-2 px-4 block whitespace-no-wrap rounded'
                        "
                        @click="setValue(category)"
                        v-for="(category, index) in categories"
                        :key="index"
                      >
                        {{ category }}
                      </li>
                    </ul>
                  </div>
                  <trac-input-error v-if="errors[0]">{{
                    errors[0]
                  }}</trac-input-error>
                </trac-validation-provider>

                <trac-input
                  v-model.trim="authUser.referral_code"
                  type="text"
                  placeholder="Referral code"
                  class="mt-6"
                  :disabled="forceDisabled"
                />

                <div class="mt-6 flex justify-center">
                  <trac-checkbox @check="checkTerms"></trac-checkbox>
                  <p class="text-xs pl-3 pr-1">I agree with</p>
                  <p
                    class="text-primaryBlue text-xs pr-1 cursor-pointer"
                    @click="openAgreement"
                  >
                    Traction Merchant Agreement
                  </p>
                  <p class="pr-0 text-xs">and</p>
                  <p class="text-xs pr-1 cursor-pointer"></p>
                  <p
                    class="text-primaryBlue text-xs pr-1 cursor-pointer"
                    @click="openTermsAndCondistions"
                  >
                    Terms &amp; Conditions.
                  </p>
                </div>

                <!-- <trac-modal size="" top="pt-5" class="" :cancel="false">
                  <div class="bg-accentBlue">
                    <h3 class="text-primaryBlue flex">
                      Traction Terms &amp; Conditions 
                    </h3>
                  </div>
                </trac-modal> -->

                <div
                  v-if="aggrementModalCtrl"
                  class="
                    bg-gray-300
                    w-screen
                    h-screen
                    top-0
                    left-0
                    fixed
                    z-30
                    p-12
                  "
                >
                  <div class="flex justify-end mb-2">
                    <svg
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      fill="none"
                      @click="clearAllModals"
                      class="bg-white rounded-full cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M35.7256 11.9222L11.9632 35.8474"
                        stroke="black"
                        stroke-width="0.917566"
                      />
                      <path
                        d="M11.8818 12.0048L35.807 35.7672"
                        stroke="black"
                        stroke-width="0.917566"
                      />
                    </svg>
                  </div>
                  <div class="bg-white rounded-sm">
                    <h1
                      class="
                        bg-accentBlue
                        py-4
                        flex
                        justify-center
                        text-primaryBlue
                        font-extrabold
                        text-lg
                      "
                    >
                      Traction Merchant Agreement
                    </h1>

                    <div class="px-16 mt-8 pb-10">
                      <p class="text-xs text-gray-600 mt-5">
                        Traction Apps built the Traction app as a Commercial
                        app. this SERVICE is provided by Traction Apps and is
                        intended for use as is.
                      </p>
                      <p class="text-xs text-gray-600 mt-5">
                        This page is used to inform visitors regarding our
                        policies with the collection, use, and disclosure of
                        Personal Information if anyone decided to use our
                        Service.
                      </p>
                      <p class="text-xs text-gray-600 mt-5">
                        If you choose to use our Service, then you agree to the
                        collection and use of information in relation to this
                        policy. The Personal Information that we collect is used
                        for providing and improving the Service. We will not use
                        or share your information with anyone except as
                        described in this Privacy Policy.
                      </p>
                      <p class="text-xs text-gray-600 mt-5">
                        The terms used in this Privacy Policy have the same
                        meanings as in our Terms and Conditions, which is
                        accessible at Traction unless otherwise defined in this
                        Privacy Policy.
                      </p>
                      <h3 class="mt-4">Information Collection and Use</h3>
                      <p class="text-xs text-gray-600 mt-5">
                        For a better experience, while using our Service, we may
                        require you to provide us with certain personally
                        identifiable information, including but not limited to
                        email, first name, last name, phone number. The
                        information that we request will be retained by us and
                        used as described in this privacy policy.
                      </p>
                      <p class="text-xs text-gray-600 mt-5">
                        The app does use third party services that may collect
                        information used to identify you.
                      </p>
                      <p class="text-xs text-gray-600 mt-5">
                        Link to privacy policy of third party service providers
                        used by the app <br />
                        <br />
                        Google Play Services
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  v-if="termsAndConditionsModalCtrl"
                  class="
                    bg-gray-300
                    w-screen
                    h-screen
                    top-0
                    left-0
                    fixed
                    z-30
                    p-12
                  "
                >
                  <div class="flex justify-end mb-2">
                    <svg
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      fill="none"
                      @click="clearAllModals"
                      class="bg-white rounded-full cursor-pointer"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M35.7256 11.9222L11.9632 35.8474"
                        stroke="black"
                        stroke-width="0.917566"
                      />
                      <path
                        d="M11.8818 12.0048L35.807 35.7672"
                        stroke="black"
                        stroke-width="0.917566"
                      />
                    </svg>
                  </div>
                  <div class="bg-white rounded-sm">
                    <h1
                      class="
                        bg-accentBlue
                        py-4
                        flex
                        justify-center
                        text-primaryBlue
                        font-extrabold
                        text-lg
                      "
                    >
                      Traction Terms &amp; Conditions
                    </h1>

                    <div class="px-16 mt-8 pb-10">
                      <p class="text-xs text-gray-600">
                        These General Terms of Service (“General Terms”) are a
                        legal agreement between you, as a current or prospective
                        customer of Traction’s services (“you,” “your”) and
                        Traction Apps Limited. Traction Apps Limited,
                        (”Traction,” “we,” “our” or “us”) and govern your use of
                        Traction’s services, including mobile applications,
                        websites, software, cloud-based solutions, hardware, and
                        other products and services (collectively, the
                        “Services”). By using any of the Services, you agree to
                        these General Terms and any policies referenced within
                        (“Policies”), including our Privacy Notice. If you are
                        using the Services on behalf of a business, you
                        represent to us that you have authority to bind that
                        business or entity to these Terms, and that business
                        accepts these Terms. You should read all of our Terms
                        carefully.
                      </p>

                      <h3 class="mt-4">1. Traction Account Registration</h3>
                      <p class="text-xs text-gray-600">
                        You must open an account with us (a “Traction Account”)
                        to use the Services. During registration we will ask you
                        for information, which may include but is not limited
                        to, your name and other personal information. You must
                        provide accurate and complete information in response to
                        our questions, and you must keep that information
                        current. You are fully responsible for all activity that
                        occurs under your Traction Account, including for any
                        actions taken by persons to whom you have granted access
                        to the Traction Account. We reserve the right to change
                        the account type, suspend or terminate the Traction
                        Account of anyone who provides inaccurate, untrue, or
                        incomplete information, or who fails to comply with the
                        account registration requirements.
                      </p>

                      <h3 class="mt-4">
                        2. Revisions, Disclosures and Notices
                      </h3>
                      <p class="text-xs text-gray-600">
                        We may amend the Terms at any time with notice that we
                        deem to be reasonable under the circumstances, by
                        posting the revised version on our website or
                        communicating it to you through the Services (each a
                        “Revised Version”). The Revised Version will be
                        effective as of the time it is posted, but will not
                        apply retroactively. Your continued use of the Services
                        after the posting of a Revised Version constitutes your
                        acceptance of such Revised Version.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="mt-8 flex items-center justify-center flex-col">
                  <div class="flex">
                    <trac-button
                      :disabled="isLoading"
                      v-if="current !== 1"
                      @button-clicked="current -= 1"
                      variant="outline"
                      class="w-48 mr-5"
                      ><div class="leading-7">PREVIOUS</div></trac-button
                    >
                    <trac-button buttonType="submit" class="w-48"
                      ><div class="leading-7">
                        {{ currentFormState ? "NEXT" : "SIGN UP" }}
                      </div></trac-button
                    >
                  </div>
                  <p class="text-xs text-primaryGray text-center mt-6">
                    By clicking “Sign Up” I agree to Traction Terms of Service
                    and Privacy. <br />
                    Already have an account?
                    <router-link
                      to="/login"
                      class="text-primaryBlue font-medium"
                      >Login</router-link
                    >
                  </p>
                </div>
              </div>
            </form>
          </trac-validation-observer>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { FETCH_ALL_DATA } from "../browser-db-config/utilityMethods";
import {
  ADD_AUTH_USER,
  GET_LOCAL_DB_DATA,
  GET_USER_TOKEN,
  SAVE_LOCAL_DB_DATA,
} from "../browser-db-config/localStorage";
import ClickOutside from "vue-click-outside";
import {
  CLEAR_ALL_DATA_IN_DB,
  SAVE_OBJECT_STORE,
} from "../browser-db-config/indexedDB";
import { eventBus } from "./../main";

export default {
  data() {
    return {
      isLoading: false,
      termsAccepted: false,
      forceDisabled: false,
      termsAndConditionsModalCtrl: false,
      aggrementModalCtrl: false,
      placeholder: "Select Category",
      current: 1,
      emailConfirmed: false,
      active: false,
      allCriteria: [],
      passedCriteria: [],
      authUser: {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        password: "",
        businessName: "",
        businessCategory: "",
        referral_code: "",
      },
      authUserData: {
        userData: null,
        businessData: null,
        tokenTimeStamp: null,
        secondaryTokenData: null,
      },
      categories: [
        "Retail",
        "Services",
        "Fashion & Accessories",
        "Food & Beverage",
        "Electronics & Computers",
        "Furniture",
        "Agro business",
        "Beauty, Saloon & Spa",
        "Fitness & Gyms",
        "Laundry & Dry cleaning",
        "Interior decorator",
        "Event planner",
        "Designer/Software developer",
        "Other retail",
        "Other service provider",
      ],
    };
  },
  computed: {
    currentFormState() {
      return this.current < 3;
    },
    hasNumber() {
      return /\d/.test(this.authUser.password);
    },
    hasLowerCase() {
      return /[a-z]/.test(this.authUser.password);
    },
    hasUpperCase() {
      return /[A-Z]/.test(this.authUser.password);
    },
    hasMinChar() {
      return this.authUser.password.length > 7;
    },
    hasSymbol() {
      const regex = /[-+_!@#$*%^&.,?/><) (|;:"\\']/;
      return regex.test(this.authUser.password);
    },
    // passwordBarColor() {
    //   switch (this.authUser.password) {
    //     case 1:
    //       return { title: "doesn't cut it", color: "#FEA04A" };
    //     case 2:
    //       return { title: "is just there", color: "#FEA04A" };
    //     case 3:
    //       return { title: "is fair", color: "#FEA04A" };
    //     case 4:
    //       return { title: "is okay", color: "#FEA04A" };
    //     case 5:
    //       return { title: "is ready", color: "#FEA04A" };
    //     default:
    //       return { title: "doesn't cut it", color: "#FEA04A" };
    //   }
    // },
    completedPasswordMeter() {
      this.allCriteria = [
        this.hasNumber,
        this.hasLowerCase,
        this.hasUpperCase,
        this.hasMinChar,
        this.hasSymbol,
      ];
      this.passedCriteria = this.allCriteria.filter((criterium) => criterium);

      return (this.passedCriteria.length * 100) / this.allCriteria.length;
    },
    ratingPassword() {
      switch (this.passedCriteria.length) {
        case 1:
          return { title: "doesn't cut it", color: "#F00" };
        case 2:
          return { title: "is just there", color: "#FE6B40" };
        case 3:
          return { title: "is fair", color: "#FEA04A" };
        case 4:
          return { title: "is ready", color: "#FADB02" };
        case 5:
          return { title: "is okay", color: "#11A529" };
        default:
          return { title: "doesn't cut it", color: "#F00" };
      }
    },
  },
  directives: {
    ClickOutside,
  },

  methods: {
    checkTerms(e) {
      this.termsAccepted = e.checked;
    },
    close() {
      setTimeout(() => {
        this.active = false;
      }, 500);
    },
    toggleActive() {
      this.active = !this.active;
    },
    setValue(x) {
      this.authUser.businessCategory = x;
    },
    addPersonalInfo() {
      this.$MixpanelReset(); // Reset Mixpanel, for instances where someone signs up from a logged in state
      this.current += 1;
      this.$TrackEvent('TA_MERCHANT_APP_SIGNUP_FIRST_LAST_NAME')
    },
    addContactInfo() {
      this.confirmEmail();
    },
    clearAllModals() {
      this.aggrementModalCtrl = false;
      this.termsAndConditionsModalCtrl = false;
    },
    openTermsAndCondistions() {
      this.termsAndConditionsModalCtrl = true;
    },
    openAgreement() {
      this.aggrementModalCtrl = true;
    },
    signUserUpAction() {
      // if (this.current === 1) {
      //   if (this.authUser.firstName === "" || this.authUser.lastName === "") {
      //     alert("Please fill in first and last names.");
      //   } else {
      //     this.current += 1;
      //   }
      // } else if (this.current === 2) {
      //   if (this.authUser.email === "" || this.authUser.password === "") {
      //     alert("All fields are required.");
      //   } else {
      //     // Confirm Email
      //     this.confirmEmail();
      //   }
      // } else if (this.current === 3) {
      //   // Sign user up
      // }
      if (this.termsAccepted) {
        this.signUserUp();
      } else {
        eventBus.$emit("trac-alert", {
          message: "Please you need to accept terms and conditions.",
        });
      }
    },
    async confirmEmail() {
      this.isLoading = true;
      await this.$store.dispatch("CHECK_EMAIL", this.authUser);
      const res = this.$store.getters["GET_CHECK_EMAIL_RES"];
      if (res.message === "user not found.") {
        this.current += 1;
        this.$TrackEvent('TA_MERCHANT_APP_SIGNUP_EMAIL_PASSWORD')
      } else {
        // alert(res.message || "Error: Network error occured.");
        eventBus.$emit("trac-alert", {
          message: res.message || "Error: Network error occured.",
        });
      }
      this.isLoading = false;
    },
    async signUserUp() {
      this.isLoading = true;
      const payload = {
        first_name: this.authUser.firstName,
        last_name: this.authUser.lastName,
        email: this.authUser.email,
        password: this.authUser.password,
        referral_code: this.authUser.referral_code,
        role: "admin",
        user_type: this.authUser.businessCategory,
      };

      await this.$store.dispatch("SIGN_USER_UP", payload);
      const res = this.$store.getters["GET_SIGN_UP_RES"];
      if (res.status) {
        this.saveData("traction-app-user-data", res.data);
        
        await this.signUserBusinessUp();
      } else {
        // alert(res.message || "Error: Network error occured.");
        eventBus.$emit("trac-alert", {
          message: res.message || "Error: Network error occured.",
        });
      }
      this.isLoading = false;
    },
    async signUserBusinessUp() {
      this.isLoading = true;
      const payload = {
        name: this.authUser.businessName,
        email: this.authUser.email,
        category: this.authUser.businessCategory,
        phone: this.authUser.phoneNumber,
        referral_code: this.authUser.referral_code,
        address: {
          country: "",
          city: "",
          state: "",
          street: "",
        },
      };

      await this.$store.dispatch("SIGN_USER_BUSINESS_UP", payload);
      const res = this.$store.getters["GET_SIGN_USER_BUSINESS_UP_RES"];
      if (res.status) {
        this.$TrackEvent('TA_MERCHANT_APP_CREATE_BUSINESS')
        await this.logUserIn();
      } else {
        // alert(res.message || "Error: Network error occured.");
        eventBus.$emit("trac-alert", {
          message: res.message || "Error: Network error occured.",
        });
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    async logUserIn() {
      const payload = {
        email: this.authUser.email,
        password: this.authUser.password,
      };
      await this.$store.dispatch("LOG_USER_IN", payload);
      const res = this.$store.getters["GET_LOGIN_RES"];

      if (res.status) {
        // await CLEAR_ALL_DATA_IN_DB();
        this.saveData("traction-app-user-data", res.data);
        // Save the login response
        this.authUserData.userData = res.data;

        await this.fetchBusinessUserDetails();

        await SAVE_OBJECT_STORE("auth_data_db", "auth_data", res.data);
      } else {
        // alert(res.message || "Error: Network error occured.");
        eventBus.$emit("trac-alert", {
          message: res.message || "Error: Network error occured.",
        });
        this.isLoading = false;
      }
    },
    fetchLoyaltyPointToken() {
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Content-Type", "application/json;charset=utf-8");
      myHeaders.append("Authorization", "Bearer " + GET_USER_TOKEN());

      // Fetch Token
      fetch(`https://tractionapp-stage.herokuapp.com/auth/loyalty/access`, {
        method: "POST",
        headers: myHeaders,
      })
        .then((res) => res.json())
        .then(async (data) => {
          // Add secondary data for perssistent auth data
          this.authUserData.secondaryTokenData = data.data;

          this.$store.dispatch("DOWNLOAD_CUSTOMERS_POINTS", data);
          // FETCH_ALL_DATA();

          // Check is store credit for buiness is active
          this.$store.dispatch("CHECK_BUSINESS_STORE_CREDIT_IS_ACTIVE");

          // Add auth user to list of auth users
          ADD_AUTH_USER(this.authUserData);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async fetchBusinessUserDetails() {
      await this.$store.dispatch("FETCH_BUSINESS_USER_DETAILS");
      const res = this.$store.getters["GET_BUSINESS_USER_RES"];

      if (res.status) {
        this.saveData("traction-app-current-user-business-data", res.data);
        this.authUserData.businessData = res.data;
        this.authUserData.tokenTimeStamp = GET_LOCAL_DB_DATA("auth-token-date");

        this.fetchLoyaltyPointToken();

        // Add auth user to list of auth users
        ADD_AUTH_USER(this.authUserData);

        await SAVE_OBJECT_STORE("business_data_db", "business_data", res.data);
        this.$TrackEvent('TA_MERCHANT_APP_ENTER_APP_AFTER_SIGNUP')
        this.$SetIdentity(this.authUserData.businessData.user_id)
        this.$SetPerson(res.data)
        this.$MixPanelRegister(res.data)
        // this.$SetPersonalAttribute('$email', this.authUserData.businessData.email)
        this.$router.push({ name: "Dashboard" });
      } else {
        // alert(res.message || "Error: Network error occured.");
        eventBus.$emit("trac-alert", {
          message: res.message || "Error: Network error occured.",
        });
        this.isLoading = false;
      }
    },
    saveData(localStorageName, dataToStore) {
      SAVE_LOCAL_DB_DATA(localStorageName, dataToStore);
    },
  },
  created() {
    if (this.$route.query && this.$route.query.name) {
      if (this.$route.query.name) {
        this.authUser.referral_code = this.$route.query.name;
        this.forceDisabled = true;
      }
    }
    // const exclusions = [
    //   'localhost:',
    //   'tractionapps.co'
    // ]
    // const referrer = document.referrer
    // console.log('Entered Signup: ', referrer)
    // const isExcluded = exclusions.find(item.toLowerCase().includes(referrer))
    // if(isExcluded) {
    //   console.log('Exclusion found. do not track')
    // } else {
    //   console.log('Allowed Referrer. track')
    // }
  },
};
</script>

<style scoped>
span {
  pointer-events: none;
  position: absolute;
  left: 1rem;
  top: 0;
  transition: 0.2s;
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  opacity: 0.5;
}

.input-area:focus + span,
.input-area:not(:placeholder-shown) + span {
  opacity: 1;
  transform: scale(0.75) translateY(-100%) translateX(-30px);
  left: 1.6rem;
  top: -0.45rem;
  background: white;
  padding: 0 5px;
  font-size: 0.9rem;
  text-transform: uppercase;
}
.input-area:focus + .input-area:-webkit-autofill + span {
  opacity: 0;
}
.input-area:-webkit-autofill .input-area:placeholder-shown {
  opacity: 0 !important;
}
</style>
